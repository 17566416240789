.container-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .25rem;
  padding-left: 40px;
  padding-right: 40px;
  color: #444;
}

.container-project {
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.container-ios {
  display: flex;
  flex-direction: column;
  padding: 30px;
  padding-bottom: 24px;
  width: 300px;
}

.title {
  font-weight: 700;
  white-space: nowrap;
  border-bottom: 1.5px solid #eaeaea;
  margin-bottom: 1.15rem;
  padding-bottom: .5rem;
  margin: 1.414rem 0 .5rem;
  line-height: 1.42;
  white-space: pre-wrap;
  text-align: center;
}

.description-main {
  font-family: "SF Pro-Regular", Helvetica;
  font-weight: 400;
  text-align: center;
  white-space: pre-wrap;
  font-size: 18px;
  padding-top: 12px;
  padding-bottom: 20px;
  max-width: 700px;
  min-width: 400px;
}

#description-main-technologies {
  font-family: "SF Pro-Regular", Helvetica;
  font-style: italic;
  padding-top: 10px;
}

.header {
  font-family: "SF Pro-Bold", Helvetica;
  font-weight: 700;
  font-size: 20px;
  white-space: pre-wrap;
  padding: 12px;
  margin-top: -24px;
}

.description {
  font-family: "SF Pro-Regular", Helvetica;
  white-space: pre-wrap;
  padding: 12px;
  padding-top: 24px;
}

.gif {
  object-fit: cover;
  height: 500px;
  border-radius: 35px;
  box-shadow: 5px 5px 5px gray;
}

.video-hydrate {
  position: inherit;
  object-fit: cover;
  height: 500px;
  width: 300px;
  z-index: inherit;
  border-radius: 15px;
}

.container-hydrate {
  display: flex;
  flex-direction: column;
  padding: 30px;
  padding-bottom: 24px;
  width: 360px;
}

.img-sikfan {
  width: 400px;
  padding: 20px;
}

.link-about {
  text-align: center;
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 40px;
}

.link-about > div > a {
  font-size: 20px;
}