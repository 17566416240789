.container-about {
  justify-content: center;
  align-items: center;
}

.container-intro {
  max-width: 1020px;
  margin: 20px auto;
  padding: 20px;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
}

.intro-text {
  font-size: 18px;
  padding: 30px;
  padding-left: 40px;
}

.image-cropper {
  min-width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-left: 20px;
}

@media screen and (max-width: 1080px) {
  .image-cropper {
    min-width: 150px;
    height: 150px;
  }

  .container-intro {
    max-width: 760px;
  }
}

@media screen and (max-width: 760px) {
  .image-cropper {
    width: 150px;
    height: 150px;
  }

  .container-intro {
    max-width: 485px;
    flex-direction: column;
  }

  .image-cropper {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin-left: 20px;
  }
}

.gif-about {
  object-fit: cover;
  border-radius: 35px;
  display: block;
  margin-bottom: 40px;
  opacity: 90%;
}

.about-extra {
  margin: 20px;
}

.about-extra > img {
  width: 450px;
}

.about-extra > p {
  text-align: center;
}

#about-gif-description {
  margin-top: -30px;
}

.center {
  align-items: center;
  justify-content: center;
  position: relative;
  align-content: center;
  align-self: center;
}

#portrait {
  margin-left: -5px;
  height: 110%;
}

a:has(.about-link-animation) {
  --fa-animation-duration: 0s;
}

a:has(.about-link-animation):hover,
a:has(.about-link-animation):focus,
a:has(.about-link-animation):active {
  --fa-animation-duration: 2s;
}