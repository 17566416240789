#marathon-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: .25rem;
  color: #444;
}

#marathon-container > h1 {
  font-weight: bold;
  white-space: pre-wrap;
  border-bottom: 1.5px solid #eaeaea;
  margin-bottom: 1.15rem;
  padding-bottom: .5rem;
  margin: 1.414rem 0 .5rem;
  line-height: 1.42;
}

#marathon-container > h3 {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  width: 50vw;
  min-width: 400px;
  margin: 20px;
}

#marathon-container > #figma-embed {
  width: 75vw;
}

.img-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  row-gap: 20px;
}

.slide-img {
  width: 400px;
}

#figma-embed {
  border: 10px solid rgba(0, 0, 0, 0.1);
  justify-content: center;
  width: 99%;
  height: 1000px;
  margin: 10px;
}