.fade-in {
  color: aliceblue;
  transition: opacity 1.5s ease;
}

.fade-out {
  color: aliceblue;
  opacity: 0;
  transition: opacity 1.5s ease;
}

.text {
  color: aliceblue;
  position: absolute;
  left: 40%;
}

.text-animation-container > h2 {
  font-size: 36px;
  padding-top: 15px;
  letter-spacing: 1px;
}

@media screen and (max-width: 960px) {
  .text-animation-container > h2 {
    font-size: 28px;
  }
}

@media screen and (max-width: 768px) {
  .text-animation-container > h2 {
    font-size: 20px;
  }
}